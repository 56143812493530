<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div @click="changeHeadIndex(index)" class="li" :class="{ active: index == headIndex }" v-for="(item, index) in 2"
          :key="index">
          {{ index == 0 ? "房源" : "客源" }}
        </div>
        <div class="li">
          <router-link class="" to="/personage/auditDH"></router-link>
        </div>
        <div class="li">
          <router-link to="/personage/auditDT"></router-link>
        </div>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div @click="changeStatus(index)" class="li" :class="{ active: index == statusIndex }" v-for="(item, index) in 3"
          :key="index">
          {{ index == 0 ? "审核中" : index == 1 ? "审核成功" : "审核失败" }}
        </div>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--honor-head start-->
    <div class="honor-head">
      <div class="head-nav">
        <div @click="changeType(0)" class="li nav-link" :class="{ active: 0 == typeIndex }">我的审核</div>
        <div v-if="userInfo.position == 1" @click="changeType(1)" class="li nav-link" :class="{ active: 1 == typeIndex }">
          审核他人
        </div>
      </div>
    </div>
    <!--honor-head end-->
    <!--ind-main start-->
    <div class="ind-main" v-if="list.length">
      <!--rate_page_list start-->
      <div class="rate_page_list" v-if="headIndex == 0">
        <ul class="personage_ul">
          <li v-for="(item, index) in list" :key="index">
            <div class="personage_ul_img m-r-20">
              <img :src="item.fang_fengmian_image" alt="" />
              <div class="personage_img_marking">{{ item.fang_level }}</div>
            </div>
            <div class="personage_ul_main f-1">
              <div class="personage_ul_name">{{ item.house_name }}</div>
              <div class="personage_ul_title">
                {{ item.xiaoqu_loudong }} | {{ item.fang_xing }} | {{ item.fang_mianji }}m² | {{ item.dian_number }}次 | {{
                  item.name }}
              </div>
              <div class="personage_ul_tags">
                <span class="tag" v-for="(em) in item.tag" :key="em">{{ em }}</span>
              </div>
              <div class="personage_ul_price flex-between">
                <div class="flex">
                  <!-- <div class="personage_ul_price_left m-r-20">
                    <span>{{ item.zong_price }}万</span>
                    <img src="../../assets/image/personage/up2.png" alt="" />
                  </div> -->
                  <div class="personage_ul_price_left">
                    <span>{{ item.can_price }}元</span>
                    <!-- <img src="../../assets/image/personage/up1.png" alt="" /> -->
                  </div>
                </div>
              </div>
              <div class="dh-reason">
                删除原因：{{ item.desc }}
              </div>
              <div class="dh-reason" v-if="statusIndex == 2">
                拒绝原因：{{ item.audit_remark }}
              </div>
              <div class="dh-date">时间：{{ item.createtime }}</div>
              <div class="dh-bot">
                <template v-if="typeIndex == 0">
                  <el-button @click="changeCancel(1, item)" class="audit-list-btn red"
                    v-if="statusIndex == 0">取消</el-button>
                  <el-button class="audit-list-btn blue" v-else-if="statusIndex == 2">重新提交</el-button>
                </template>
                <template v-else-if="statusIndex == 0 && typeIndex == 1">
                  <el-button class="audit-list-btn red m-r-10" @click="$refs.popr.open(item.id, 1)">拒绝</el-button>
                  <el-button class="audit-list-btn blue" @click="consentDel(item)">同意删除</el-button>
                </template>
              </div>
            </div>
          </li>
        </ul>
        <div class="paging" v-if="list.length != 0">
          <el-pagination background @current-change="changePage" layout="prev, pager, next, jumper, ->, total"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <!--rate_page_list end-->
      <!--dt-list start-->
      <div class="dt-list" v-else>
        <!--dt-item start-->
        <div class="dt-item" v-for="(item, index) in list" :key="index">
          <div class="dt-hd">
            <span class="name m-r-20">{{ item.name }}</span>
            <span class="sex m-r-15">{{ item.sex }}</span>
            <span class="tag-b m-r-10">{{ getBooth(item.booth_type) }}</span>
            <span class="tag-o m-r-10" v-for="(item, index) in item.finish_type" :key="index">{{ item }}</span>
            <div class="price">预算<span class="size">{{ item.price }}元</span></div>
          </div>
          <div class="dt-bd">
            <div class="li m-r-30">
              <span class="color-9">意向类型：</span>{{ item.purpose_type }}
            </div>
            <div class="li m-r-30">
              <span class="color-9">房源类型：</span>{{ getHouse(item.house_type) }}
            </div>
            <div class="li m-r-30">
              <span class="color-9">意向位置：</span>{{ item.city }}
            </div>
            <div class="li m-r-30">
              <span class="color-9">录入日期：</span>{{ item.createtime }}
            </div>
            <div class="li">
              <span class="color-9">客户来源：</span>{{ item.client_source }}
            </div>
          </div>
          <div class="dt-reason">
            删除原因：{{ item.remark }}
          </div>
          <div class="dt-reason m-t-10 m-b-10" v-if="item.status == 3 && statusIndex == 2">
            失败原因：{{ item.desc }}
          </div>
          <div class="dt-bot">
            <el-button @click="changeCancel(2, item)" class="audit-list-btn red"
              v-if="statusIndex == 0 && typeIndex == 0 && item.status == 1">取消</el-button>
            <el-button class="audit-list-btn blue"
              v-else-if="statusIndex == 2 && typeIndex == 0 && item.status == 3">重新提交</el-button>
            <template v-else-if="statusIndex == 0 && typeIndex == 1">
              <el-button class="audit-list-btn red m-r-10" @click="$refs.popr.open(item.id, 8)">拒绝</el-button>
              <el-button class="audit-list-btn blue" @click="delVisitor(item, index)">同意删除</el-button>
            </template>
          </div>
        </div>
        <!--dt-item end-->
        <div class="paging" v-if="list.length != 0">
          <el-pagination background @current-change="changePage" layout="prev, pager, next, jumper, ->, total"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <!--dt-list end-->
    </div>
    <!--ind-main end-->
    <audit-pop-re ref="popr" @succend="init"></audit-pop-re>
  </div>
</template>

<script>
import AuditPopRe from '../../components/AuditPopRe.vue'
import { mapGetters } from "vuex";
export default {
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  components: { AuditPopRe },
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      headIndex: 0, //房源客源
      statusIndex: 0, //审核状态
      typeIndex: 0, //我审核他人审核
    };
  },
  methods: {
    getBooth(type) {
      let txt = ''
      switch (type) {
        case 1:
          txt = '展位'
          break;
        case 2:
          txt = 'A类'
          break;
        case 3:
          txt = 'B类'
          break;
        case 4:
          txt = 'C类'
          break;
        case 5:
          txt = 'D类'
          break;
        default:
          break;
      }
      return txt;
    },
    getHouse(type) {
      let txt = ''
      switch (type) {
        case 1:
          txt = '新房'
          break;
        case 2:
          txt = '住宅'
          break;
        case 3:
          txt = '公寓'
          break;
        case 4:
          txt = '写字楼'
          break;
        case 5:
          txt = '商铺'
          break;
        case 6:
          txt = '别墅'
          break;
        default:
          break;
      }
      return txt;
    },
    //删除客源
    delVisitor(item, index) {
      this.$axios.agreePassenger({ id: item.id }).then(res => {
        this.$message({
          type: 'success',
          message: res.msg
        });
        this.list.splice(index, 1);
      })
    },
    changePage(e) {
      this.page = e
      this.getdeleteAudit()
    },
    //删除房源
    consentDel(item) {
      this.$axios.agreeHouseDel({ id: item.id }).then(res => {
        this.init();
        this.$message({
          type: 'success',
          message: res.msg
        });
      })
    },
    changeStatus(index) {
      this.typeIndex = 0
      this.statusIndex = index
      this.init()
    },
    changeType(index) {
      this.typeIndex = index
      this.init()
    },
    //取消1房源2客源
    changeCancel(type, item) {
      this.$confirm('是否取消继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios[type == 1 ? 'cancelHousing' : 'cancelPassenger']({
          id: item.id
        }).then(res => {
          this.init();
          this.$message({
            type: 'success',
            message: res.msg
          });
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //切换头部
    changeHeadIndex(index) {
      this.headIndex = index;
      this.init();
    },
    //初始化
    init() {
      this.page = 1;
      this.list = [];
      this.getdeleteAudit();
    },
    //获取列表
    getdeleteAudit() {
      this.$axios[this.headIndex == 0 ? "houseDelList" : "deleteAudit"]({
        page: this.page,
        role_type: this.typeIndex + 1,
        status: this.statusIndex,
      }).then((res) => {
        res.data.data.forEach(element => {
          element.tag = element.fang_biaoqian && element.fang_biaoqian != '0' ? element.fang_biaoqian.split(',') : []
          if (this.headIndex == 1) {
            element.finish_type = element.finish_type ? element.finish_type.split(',') : []
          }
        });

        this.list = res.data.data
        this.total = res.data.total
      });
    },
  },
  created() {
    this.getdeleteAudit();
  },
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #ebebeb;

  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }

    .active {
      color: #3273f6;
    }

    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}

.message-select {
  /deep/ .el-select {
    width: 100px;
    min-width: 100px !important;
  }

  /deep/ .el-input__inner {
    border: 1px solid #ccc;
  }
}

.honor-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #fff;
  margin-bottom: 10px;
  align-items: center;

  .head-nav {
    display: flex;
  }

  .li {
    margin-right: 55px;
    line-height: 36px;
  }

  .nav-link {
    cursor: pointer;
    font-size: 16px;
    color: #666;
    line-height: 36px;
  }

  .active {
    color: #3273f6;
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.ind-add-btn-o {
  width: 88px;
  height: 36px;
  border-radius: 4px;
  background: #ffa740;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
  border: none;
}

.ind-main {
  background: #fff;
  padding: 20px 40px;
}

.personage_ul_tags {
  display: flex;
  margin: 0px -5px;

  .tag {
    background: #f5f5f5;
    height: 28px;
    border-radius: 3px;
    padding: 0 20px;
    margin: 5px;
    font-size: 14px;
    color: #999;
    line-height: 28px;
  }
}

.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}

.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}

.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}

.dh-reason {
  font-size: 14px;
  color: #666;
}

.dh-date {
  margin: 15px 0px;
  font-size: 14px;
  color: #999;
}

.dh-bot {
  display: flex;
  justify-content: flex-end;
}

.audit-list-btn {
  height: 36px;
  border-radius: 4px;
  padding: 0 0px;
  font-size: 16px;
  line-height: 36px;
  border: none;
  outline: none;
  position: relative;
  z-index: 11;
  width: 112px;
  text-align: center;
}

.audit-list-btn.blue {
  background: #3273f6;
  color: #fff;
}

.audit-list-btn.red {
  background: #fddfdf;
  color: #ff5151;
}

.personage_ul {
  li {
    padding: 25px 0;
    display: flex;
    border-bottom: 1px solid #ebebeb;

    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }

      position: relative;

      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }

    .personage_ul_main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }

      .personage_ul_title {
        color: #999999;
        font-size: 14px;
        margin: 15px 0px;
      }

      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        margin: 15px 0;

        .personage_ul_price_left {
          display: flex;
          align-items: center;

          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }

        .personage_ul_price_right {
          display: flex;
          align-items: center;

          span {
            color: #999999;
            font-size: 18px;
          }
        }

        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }

      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;

        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }

        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }

          margin-right: 10px;
        }

        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }

        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }

        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;

            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}

.dt-list {
  .dt-item {
    padding: 20px 0px;
    border-bottom: 1px solid #ebebeb;

    .dt-bot {
      display: flex;
      justify-content: flex-end;
    }

    .dt-hd {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .name {
        font-size: 18px;
      }

      .sex {
        color: #666;
        font-size: 16px;
      }

      .tag-b {
        background: #d5e3ff;
        border-radius: 3px;
        padding: 0px 10px;
        line-height: 28px;
        font-size: 14px;
        color: #3273f6;
      }

      .tag-o {
        background: rgba(155, 117, 42, 0.1);
        border-radius: 3px;
        padding: 0px 10px;
        line-height: 28px;
        font-size: 14px;
        color: #ff752a;
      }

      .price {
        font-size: 18px;
        color: #666;
      }

      .size {
        color: #ff2121;
        font-size: 20px;
      }
    }

    .dt-bd {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0px;

      .li {
        font-size: 16px;
        line-height: 1.6;
      }
    }

    .dt-reason {
      color: #666;
      font-size: 14px;
      line-height: 1.4;
    }
  }
}
</style>
